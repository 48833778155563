/*
 * @Description:
 * @Author: 小亓
 * @Date: 2023-07-24 17:19:44
 * @LastEditTime: 2023-07-25 16:29:03
 * @LastEditors: Please set LastEditors
 */
// export const API_ROOT =
//   process.env.NODE_ENV === 'production'
//     ? 'https://service.91chenzhi.cn'
//     : '/apis'

// function getApiPort() {
//   // var protocol = window.location.protocol
//   // let env = 'test'
//   // var api = '//testservice.91chenzhi.cn'y

//   // var api = 'https://dingservice.91chenzhi.cn'
//   var api = '//service.91chenzhi.cn'
//   if (location.hostname.indexOf('test.91chenzhi.cn') != -1) {
//     api = '//testservice.91chenzhi.cn'
//   } else if (location.hostname.indexOf('dingding.91chenzhi.cn') != -1) {
//     api = '//dingservice.91chenzhi.cn'
//   } else if (location.hostname.indexOf('testding.91chenzhi.cn') != -1) {
//     api = '//testservice.91chenzhi.cn'
//   } else if (location.hostname.indexOf('91chenzhi.cn') != -1) {
//     api = '//service.91chenzhi.cn'
//   }

//   return api
// }

export const bucket = () => {
  let bucket = 'test91chenzhi'
  if (location.hostname.indexOf('test.91chenzhi.cn') != -1) {
    bucket = 'test91chenzhi'
  } else if (location.hostname.indexOf('dingding.91chenzhi.cn') != -1) {
    bucket = 'ding91chenzhi'
  } else if (location.hostname.indexOf('testxuejiajia.91chenzhi.cn') != -1) {
    bucket = 'test91chenzhi'
  } else if (location.hostname.indexOf('xuejiajia.91chenzhi.cn') != -1) {
    bucket = '91chhenzhi'
  } else if (location.hostname.indexOf('91chenzhi.cn') != -1) {
    bucket = '91chhenzhi'
  }
  return bucket
}

export const API_ROOT = 'https://service.91chenzhi.cn'
export const API_ROOTS = 'https://banpaiapi.shuxiaoyi.net'
export const API_ROOTSS = 'http://47.92.122.53:8098'
