/*
 * @Description:
 * @Author: 小亓
 * @Date: 2023-07-24 17:19:44
 * @LastEditTime: 2024-01-08 17:18:53
 * @LastEditors: Please set LastEditors
 */
import { observable } from 'mobx'
import checkingIn from '../Common/Images/checking-in@3x.png'
import banner from '../Common/Images/banner@3x.png'
import album from '../Common/Images/album@3x.png'
import inform from '../Common/Images/inform@3x.png'
import education from '../Common/Images/education@2x.png'
import face from '../Common/Images/face.png'
import lavel from '../Common/Images/lavel.png'
const routerConfig = [
  {
    id: '001',
    path: '/streamer',
    icon: banner,
    label: 'streamer',
    name: '班牌横幅',
    className: 'mark_streamer'
  },
  {
    id: '002',
    path: '/circularize',
    icon: inform,
    label: 'Circularize',
    name: '班级通知',
    className: 'mark_circularize'
  },
  {
    id: '003',
    path: '/mien',
    icon: album,
    label: 'Mien',
    name: '风采相册',
    className: 'mark_mien'
  },
  {
    id: '004',
    path: '/clocking-in',
    icon: checkingIn,
    label: 'ClockingIn',
    name: '学生考勤',
    className: 'mark_clocking-in'
  },
  {
    id: '005',
    path: '/education',
    icon: education,
    label: 'Education',
    name: '班级德育',
    className: 'mark_education'
  },
  {
    id: '006',
    path: '/face',
    icon: face,
    label: 'face',
    name: '人脸录入',
    className: 'mark_face'
  },
  {
    id: '007',
    path: '/leave',
    icon: lavel,
    label: 'leave',
    name: '学生请假',
    className: 'mark_leave'
  }
]

class RouterStore {
  constructor(rootStore) {
    this.rootStore = rootStore
  }
  @observable routerConfig = routerConfig
}

export default RouterStore
