import React from 'react'
import { Provider } from 'mobx-react'
import RootStore from '../Stores'
import Router from '../Router'

import styles from './App.module.scss'
// import VConsole from 'vconsole/dist/vconsole.min.js'
const App = () => {
  // let vConsole = new VConsole() // 初始化
  // console.log('vConsole', vConsole)
  return (
    <div className={styles.App}>
      <Provider {...new RootStore()}>
        <Router />
      </Provider>
    </div>
  )
}

export default App
