import { action, observable } from 'mobx'
class MienStore {
  constructor(rootStore) {
    this.rootStore = rootStore
  }
  @observable currentAlbum = {}

  @action.bound
  setCurrentAlbum = values => {
    this.currentAlbum = values
  }
}

export default MienStore
