import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import ReactDOM from 'react-dom'
// @material-ui/core component
import { makeStyles } from '@material-ui/core/styles'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import IconButton from '@material-ui/core/IconButton'
import { amber, green } from '@material-ui/core/colors'
import Slide from '@material-ui/core/Slide'
// @material-ui/icons component
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import InfoIcon from '@material-ui/icons/Info'
import CloseIcon from '@material-ui/icons/Close'
import WarningIcon from '@material-ui/icons/Warning'

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
}
const messageStyle = makeStyles(theme => ({
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  info: {
    backgroundColor: theme.palette.primary.dark
  },
  warning: {
    backgroundColor: amber[700]
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  message: {
    maxWidth: '260px',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden', //超出的文本隐藏
    textOverflow: 'ellipsis', //溢出用省略号显示
    whiteSpace: 'nowrap'
  }
}))

// eslint-disable-next-line react/display-name
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />
})

export default function CustomMessage(props) {
  const {
    variant,
    content,
    className,
    onClose,
    duration,
    isOpen,
    ...other
  } = props
  const classes = messageStyle()
  const Icon = variantIcon[variant]
  const messageRef = useRef()
  const [state, setState] = React.useState({
    open: isOpen || false,
    vertical: 'top',
    horizontal: 'center'
  })
  const { vertical, horizontal, open } = state

  const handleClose = () => {
    const removeNode = document.getElementById('customMessage')
    if (typeof onClose === 'function') setTimeout(onClose(), 300)
    setState({ ...state, open: false })
    if (removeNode) {
      ReactDOM.unmountComponentAtNode(removeNode)
      document.body.removeChild(removeNode)
    }
  }

  // eslint-disable-next-line react/display-name
  const ContentWrapper = React.forwardRef((props, ref) => {
    return (
      <SnackbarContent
        ref={ref}
        className={clsx(classes[variant], className)}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={classes.message}>
            <Icon className={clsx(classes.icon, classes.iconVariant)} />
            {content}
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon className={classes.icon} />
          </IconButton>
        ]}
        {...other}
      />
    )
  })
  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      key={`${vertical},${horizontal}`}
      open={open}
      TransitionComponent={Transition}
      autoHideDuration={duration}
      onClose={handleClose}
    >
      <ContentWrapper
        ref={messageRef}
        onClose={handleClose}
        variant={variant}
        message={content}
      />
    </Snackbar>
  )
}

CustomMessage.defaultProps = {
  duration: 3000
}

CustomMessage.propTypes = {
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
  content: PropTypes.node,
  className: PropTypes.string,
  message: PropTypes.node,
  duration: PropTypes.number.isRequired,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired
}
