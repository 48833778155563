import { flow, action, observable } from 'mobx'
import { getWeChatAccount, queryUserHasBanpai } from '../Service/api'
import { getQueryStrings } from '../Utils/utils'
class UserStore {
  constructor(rootStore) {
    this.rootStore = rootStore
  }

  @observable queryInfo = localStorage.getItem('userData')
    ? JSON.parse(localStorage.getItem('userData'))
    : {}
  @observable userInfo = localStorage.getItem('userInfo')
    ? JSON.parse(localStorage.getItem('userInfo'))
    : {}
  // 当前用户的角色 0 普通教师 1=管理员 2=班主任
  // 是否拥有班牌权限 0 没有 1=拥有
  @observable permissionInfo = localStorage.getItem('permissionInfo')
    ? JSON.parse(localStorage.getItem('permissionInfo'))
    : {}

  @observable isFirst = false

  @action.bound
  fetchAccountInfo = flow(function* (type) {
    const pageUrl = getQueryStrings()
    let queryInfos = JSON.parse(localStorage.getItem('queryInfo'))
    if (this.isFirst) {
      return
    }
    const self = this
    // const token =
    //   pageUrl && pageUrl.token
    //     ? pageUrl.token
    //     : self.queryInfo && self.queryInfo.token
    //       ? self.queryInfo.token
    //       : ''
    // console.log(queryInfos, 'queryInfos')
    try {
      const response = yield getWeChatAccount(queryInfos.token)
      // console.log(response)
      console.log('--3')
      if (response.data.retCode == 1) {
        response.data.data.schooId = response.data.data.schoolId
        const result = response.data.data || {}
        self.userInfo = result
        self.fetchPermissionInfo(result, type)
        console.log(pageUrl, 'pageUrl')
        console.log(result, 'pageUrl')
        // localStorage.setItem('queryInfo', JSON.stringify(pageUrl))
        localStorage.setItem('userInfo', JSON.stringify(result))
        self.isFirst = true
      }
    } catch (e) {
      console.log(e.toString())
      // window.history.go(-1)
    }
  })

  @action.bound
  fetchPermissionInfo = flow(function* (userInfo, type) {
    console.log('--4')
    const self = this
    const { schooId, userCode } = userInfo
    const paramsInfo = {
      teacherCode: userCode,
      schoolId: schooId
    }
    console.log('--5')
    try {
      const response = yield queryUserHasBanpai(paramsInfo)
      console.log('--6')
      if (response.data.retCode === 1) {
        const result = response.data.data || {}
        self.permissionInfo = result
        localStorage.setItem('permissionInfo', JSON.stringify(result))
        console.log('--7')
        if (type) {
          self.rootStore.education.fetchTermWeek()
        }
      }
    } catch (e) {
      console.log(e.toString())
      // window.history.go(-1)
    }
  })
}

export default UserStore
