/***
 *  .--,       .--,
 * ( (  \.---./  ) )
 *  '.__/o   o\__.'
 *     {=  ^  =}
 *      >  -  <
 *     /       \
 *    //       \\
 *   //|   .   |\\
 *   "'\       /'"_.-~^`'-.
 *      \  _  /--'         `
 *    ___)( )(___
 *   (((__) (__)))    高山仰止,景行行止.虽不能至,心向往之。
 */

// 基于年级信息排序
import CryptoJS from 'crypto-js'
import MD5 from 'js-md5'
export const compare = property => (obj1, obj2) =>
  obj1[property] - obj2[property]
// {
//   return function(obj1, obj2) {
//     let value1 = obj1[property]
//     let value2 = obj2[property]
//     return  // 升序
//   }
// }

//获取所有的参数（通过对象的形式返回）
export const getQueryStrings = () => {
  const hrefUrl = location.href
  const url = hrefUrl.split('?')[1] || ''
  let theRequest = {}
  if (url) {
    let strs = url.split('&')
    for (let i = 0; i < strs.length; i++) {
      theRequest[strs[i].split('=')[0]] = decodeURIComponent(
        strs[i].split('=')[1]
      )
    }
  }
  return theRequest
}

// 获取路由信息
export const getPageTitle = props => {
  const pathname = window.location.pathname
  const currentRouter = props.find(item => item.path === pathname)
  return currentRouter && currentRouter.name ? currentRouter.name : '智慧班牌'
}

// 生成一定范围的随机数

export function rand(min, max) {
  return Math.floor(Math.random() * (max - min)) + min
}
// 判断form表单是否通过验证
export function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => {
    console.log(field + ':' + fieldsError[field])
    return fieldsError[field]
  })
}

/**
 * 处理长时段转换成月-日的形式
 */

export function timeToMonthAndDay(time) {
  const date = new Date(time)
  const MOnth =
    (date.getMonth() + 1 < 10
      ? '0' + (date.getMonth() + 1)
      : date.getMonth() + 1) + '-'
  const Day = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ''
  return MOnth + Day
}

/**
 * 处理长时段转换成月-日-小时-分钟的形式
 */

export function timeFormat(time) {
  const date = new Date(time)
  const year = date.getFullYear() + '-'
  const MOnth =
    (date.getMonth() + 1 < 10
      ? '0' + (date.getMonth() + 1)
      : date.getMonth() + 1) + '-'
  const Day =
    (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
  const Hours =
    (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
  const Miuntes =
    (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
  const seconds =
    date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
  return year + MOnth + Day + Hours + Miuntes + seconds
}

/**
 * 处理长时段转换成月-日-小时-分钟的形式
 */

export function timeFormatForYear(time) {
  const date = new Date(time)
  const year = date.getFullYear() + '/'
  const MOnth =
    (date.getMonth() + 1 < 10
      ? '0' + (date.getMonth() + 1)
      : date.getMonth() + 1) + '/'
  const Day =
    (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
  const Hours =
    (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
  const Miuntes =
    date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
  // const seconds =
  //   date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
  return year + MOnth + Day + Hours + Miuntes
}

/**
 * 将数字装换成汉字
 */

export function digitalConversion(num) {
  const arr1 = new Array(
    '零',
    '一',
    '二',
    '三',
    '四',
    '五',
    '六',
    '七',
    '八',
    '九'
  )
  const arr2 = new Array(
    '',
    '十',
    '百',
    '千',
    '万',
    '十',
    '百',
    '千',
    '亿',
    '十',
    '百',
    '千',
    '万',
    '十',
    '百',
    '千',
    '亿'
  ) //可继续追加更高位转换值
  if (!num || isNaN(num)) {
    return '零'
  }
  let english = num.toString().split('')
  let result = ''
  for (let i = 0; i < english.length; i++) {
    let des_i = english.length - 1 - i //倒序排列设值
    result = arr2[i] + result
    let arr1_index = english[des_i]
    result = arr1[arr1_index] + result
  }
  //将【零千、零百】换成【零】 【十零】换成【十】
  result = result.replace(/零(千|百|十)/g, '零').replace(/十零/g, '十')
  //合并中间多个零为一个零
  result = result.replace(/零+/g, '零')
  //将【零亿】换成【亿】【零万】换成【万】
  result = result.replace(/零亿/g, '亿').replace(/零万/g, '万')
  //将【亿万】换成【亿】
  result = result.replace(/亿万/g, '亿')
  //移除末尾的零
  result = result.replace(/零+$/, '')
  //将【零一十】换成【零十】
  //result = result.replace(/零一十/g, '零十');//貌似正规读法是零一十
  //将【一十】换成【十】
  result = result.replace(/^一十/g, '十')
  return result
}

// aes解密
export const decrypt = (encryptedBase64Str, CRYPTOJSKEY) => {
  CRYPTOJSKEY = MD5(CRYPTOJSKEY).toUpperCase()
  console.log('CRYPTOJSKEY :>> ', CRYPTOJSKEY)
  var vals = encryptedBase64Str.replace(/\\-/g, '+').replace(/_/g, '/')
  var options = {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  }
  var key = CryptoJS.enc.Utf8.parse(CRYPTOJSKEY)
  var decryptedData = CryptoJS.AES.decrypt(vals, key, options)
  var decryptedStr = CryptoJS.enc.Utf8.stringify(decryptedData)
  return decryptedStr
}
