/*
 * @Author: your name
 * @Date: 2020-11-27 18:01:39
 * @LastEditTime: 2020-12-01 12:01:21
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /tc-wisdomfacility-web/src/index.js
 */
import React from 'react'
import ReactDOM from 'react-dom'
import initReactFastclick from 'react-fastclick'
import FastClick from 'fastclick'
import './Common/Styles/index.scss'
import App from './Container/App'
import RootStore from './Stores'
import * as serviceWorker from './serviceWorker'

import wx from 'weixin-js-sdk'
window.$wx = wx
// import VConsole from 'vconsole'

// const vConsole = new VConsole()
// console.log('vConsole', vConsole)

initReactFastclick()
FastClick.attach(document.body)
ReactDOM.render(<App {...new RootStore()} />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
