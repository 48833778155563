import { flow, action, observable } from 'mobx'
import { queryTermWeek } from '../Service/api'
import { timeToMonthAndDay, digitalConversion } from '../Utils/utils'
import message from '../Components/Message/index'
class EducationStore {
  constructor(rootStore) {
    this.rootStore = rootStore
  }

  @observable termWeekList = []
  @observable currTime = {}

  @action.bound
  fetchTermWeek = flow(function*() {
    console.log('current', this)
    const current = this
    const currentUser = current.rootStore.user.userInfo

    try {
      if (!currentUser || !currentUser.schooId) {
        current.rootStore.user.fetchAccountInfo(true)
        return
      }
      const response = yield queryTermWeek({
        schoolId: currentUser.schooId
      })
      if (response.data.retCode == 1) {
        // 在这里进行一次数据的转换，将原有的数据添加一个字段增加到数组里面
        const currNum = response.data.data.currNum
        const list = response.data.data.termWeek
        list.forEach(item => {
          item.weekTime =
            timeToMonthAndDay(item.startTime) +
            '~' +
            timeToMonthAndDay(item.endTime) +
            ' 第' +
            digitalConversion(item.num) +
            '周'
          if (item.num == currNum) {
            current.currTime = item
          }
        })

        current.termWeekList = list || []
        // current.currTime = currNum
      } else {
        message.error(response.data.retMsg)
      }
      console.log('current', current)
    } catch (error) {
      console.log(error)
    }
  })
}

export default EducationStore
