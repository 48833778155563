import React from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import DocumentTitle from 'react-document-title'
import { getPageTitle } from '../Utils/utils'

const BasicLayout = props => {
  const { children, routerConfig } = props
  return (
    <DocumentTitle title={getPageTitle(routerConfig)}>{children}</DocumentTitle>
  )
}
BasicLayout.propTypes = {
  children: PropTypes.node,
  routerConfig: PropTypes.array
}
export default inject(({ router }) => ({
  routerConfig: router.routerConfig
}))(observer(BasicLayout))
