import axios from 'axios'
import { API_ROOTS } from '../config'
import message from '../Components/Message'

const fetchRequest = axios.create({
  timeout: 30000,
  baseURL: API_ROOTS,
  headers: {
    'Content-Type': 'application/json'
  }
});

fetchRequest.interceptors.request.use(
  config => {
    config.headers = config.headers || {};
    const queryInfo = localStorage.getItem('userData')
      ? localStorage.getItem('userData')
      : '';
    const token =
      queryInfo && queryInfo !== '' ? JSON.parse(queryInfo).token : '';
    if(token) config.headers.Authorization = `${token}`;
    return config
  },
  error => {
    return Promise.reject(error)
  }
);
fetchRequest.interceptors.response.use(
  response => {
    return response
  },
  error => {
    let response = error.response || '';
    let status = response.status || '';
    switch(status) {
      case 401:
      case 403:
        if(!Object.is(window.location.pathname, '/')) {
          window.location.pathname = '/'
        }
        break;
      case '413':
        message.error('上传文件总大小不能超过200M！！！');
        break;
      case 408:
        message.error('网络链接超时！');
        break;
      case 404:
        message.error('客户端请求错误！');
        break;
      case 500:
        message.error('服务器错误，请稍后再试！');
        break;
      case 504:
        message.error('系统繁忙，请重新刷新页面！');
        break;
      default:
      // message.error('未知错误！')
    }
    return Promise.reject(error)
  }
);
export default fetchRequest
