import { configure } from 'mobx'
import UserStore from './user'
import RouterStore from './router'
import CircularizeStore from './circularize'
import MienStore from './mien'
import Education from './education'

// 不允许在动作之外进行状态修改
configure({ enforceActions: 'observed' })

class RootStore {
  constructor() {
    this.user = new UserStore(this)
    this.router = new RouterStore(this)
    this.circularize = new CircularizeStore(this)
    this.mien = new MienStore(this)
    this.education = new Education(this)
  }
}

export default RootStore
