import React, { Component, Suspense } from 'react'
import {
  BrowserRouter as Routers,
  Switch,
  Route,
  Redirect
} from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import PropTypes from 'prop-types'
// Custom Components
import PageLoading from '../Components/PageLoading/PageLoading'
import BasicLayout from '../Layouts/BasicLayout'
import { getQueryStrings } from '../Utils/utils'
// Lazy Components
const HomePage = React.lazy(() => import('../Views/Home/HomePage'))
const Circularize = React.lazy(() => import('../Views/Circularize'))
const Streamer = React.lazy(() => import('../Views/Streamer'))
const Mien = React.lazy(() => import('../Views/Mien'))
const ClockingIn = React.lazy(() => import('../Views/ClockingIn/ClockingIn'))
const Education = React.lazy(() => import('../Views/Education'))
const BingBanPai = React.lazy(() =>
  import('../Views/Home/bingBanpai/bingBanpai')
)
const ExternalJump = React.lazy(() =>
  import('../Views/ExternalJump/externalJump')
)

const Face = React.lazy(() => import('../Views/face/face'))
const Leave = React.lazy(() => import('../Views/leave/leave'))

@inject(({ user }) => ({
  permissionInfo: user.permissionInfo,
  fetchAccountInfo: user.fetchAccountInfo
}))
@observer
class Router extends Component {
  constructor(props) {
    // console.log(props, 'props')
    super(props)
  }
  render () {
    // let { permissionInfo } = this.props
    // console.log(permissionInfo)
    // console.log('1111')
    // console.log(JSON.stringify(permissionInfo))
    // console.log(getQueryStrings(), 'index')
    let tap = JSON.stringify(getQueryStrings())
    localStorage.setItem('queryInfo', tap)
    // if (
    //   JSON.stringify(permissionInfo) == '{}' &&
    //   getQueryStrings().role != '-1'
    // ) {
    //   fetchAccountInfo()
    //   return (
    //     <BasicLayout>
    //       <Routers keyLength={12} basename="/h5">
    //         <Suspense fallback={<PageLoading />}>
    //           <Switch></Switch>
    //         </Suspense>
    //       </Routers>
    //     </BasicLayout>
    //   )
    // }
    // console.log('11113')
    return (
      <BasicLayout>
        <Routers keyLength={12} basename="/">
          <Suspense fallback={<PageLoading />}>
            <Switch>
              <Route exact path="/" render={() => <Redirect to="/home" />} />
              <Route path="/home" render={props => <HomePage {...props} />} />
              <Route
                path="/streamer"
                render={props => <Streamer {...props} />}
              />
              <Route
                path="/circularize"
                render={props => <Circularize {...props} />}
              />
              <Route path="/mien" render={props => <Mien {...props} />} />
              <Route
                path="/clocking-in"
                render={props => <ClockingIn {...props} />}
              />
              <Route
                path="/education"
                render={props => <Education {...props} />}
              />
              <Route
                path="/externalJump"
                render={props => <ExternalJump {...props} />}
              />
              <Route
                path="/bingBanPai"
                render={props => <BingBanPai {...props} />}
              />
              <Route path="/face" render={props => <Face {...props} />} />
              <Route path="/leave" render={props => <Leave {...props} />} />
            </Switch>
          </Suspense>
        </Routers>
      </BasicLayout>
    )
  }
}

Router.propTypes = {
  permissionInfo: PropTypes.object,
  fetchAccountInfo: PropTypes.func
}

export default Router
