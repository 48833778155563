import fetchRequest from './fetch'
import fetchRequests from './fetchs'
import fetchRequestss from './fetchss'
/*
 * 获取用户信息
 * */

export const getWeChatAccount = token => {
  const dataBase = { accountToken: token || '' }
  return fetchRequest({
    url: '/banpai/base/queryUserInfoByAccountToken',
    method: 'get',
    params: dataBase
  })
}

/*
 * 获取用户新的tocken
 * */

export const getTokenValue = userInfo => {
  return fetchRequest({
    url: 'TeacherWechat/api/teacher/wechat/service/getTokenValue',
    method: 'post',
    data: userInfo
  })
}

/*
 * 210鉴权
 * */

export const requireUserInfo = userInfo => {
  return fetchRequest({
    url: '/base/uc/service/lezhi/requireUserInfo',
    method: 'get',
    params: userInfo
  })
}

/*
 * 获取用户是否拥有电子班牌权限
 * */
export const queryUserHasBanpai = userInfo => {
  return fetchRequest({
    url: '/banpai/base/queryUserHasBanpai',
    method: 'get',
    params: userInfo
  })
}

/*
 * 获取横幅模式操作记录
 * */

export const getStreamerRecords = query => {
  return fetchRequest({
    url: '/banpai/show/getRecord',
    method: 'get',
    params: query
  })
}

/*
 * 获取风采
 * */

export const getGroup = groupDetails => {
  return fetchRequest({
    url: '/banpai/fc/group/getGroup',
    method: 'post',
    data: groupDetails
  })
}

/*
 *  发布风采
 * */

export const releaseElegant = elegantInfo => {
  return fetchRequest({
    url: '/banpai/show/release',
    method: 'post',
    data: elegantInfo
  })
}

/*
 *  删除风采
 * */

export const deleteElegant = elegant => {
  return fetchRequest({
    url: '/banpai/fc/deleteFc',
    method: 'get',
    params: elegant
  })
}

/*
 * 删除风采发布记录
 * */
export const deleteStreamerRecord = queryId => {
  return fetchRequest({
    url: '/banpai/show/deleteRecord',
    method: 'get',
    params: { id: queryId }
  })
}

/*
 * 上传通知图片
 * */
export const uploadNoticePic = fileLists => {
  return fetchRequest({
    url: '/banpai/api/notice/uplaodNoticePic',
    method: 'post',
    data: fileLists
  })
}

/*
 * 保存通知
 * */
export const saveNotice = noticeInfo => {
  return fetchRequest({
    url: '/banpai/api/notice/saveOrUpdateNotice',
    method: 'post',
    data: noticeInfo
  })
}

/*
 * 查询通知
 * */

export const inquireNotice = queryInfo => {
  return fetchRequest({
    url: '/banpai/api/notice/findNotice',
    method: 'post',
    data: queryInfo
  })
}

/*
 * 删除通知
 * */

export const deleteNotice = noticeId => {
  return fetchRequest({
    url: '/banpai/api/notice/deleteNotice',
    method: 'get',
    params: { id: noticeId }
  })
}

/*
 * 获取班级扩展信息/班训，轮播图，logo等
 * */

export const getClassExtendedInfo = classInfo => {
  return fetchRequest({
    url: '/banpai/api/class/queryClassExtInfo',
    method: 'post',
    data: {
      ...classInfo,
      sn: 'a'
    }
  })
}

/*
 * 上传班牌logo
 * */

export const uploadClassLogo = paramInfo => {
  return fetchRequest({
    url: '/banpai/api/class/uploadLogo',
    method: 'post',
    data: paramInfo
  })
}

/*
 * 上传风采
 * */

export const uploadElegant = filesInfo => {
  return fetchRequest({
    url: '/banpai/fc/uplaodFc',
    method: 'post',
    data: filesInfo
  })
}

/*
 * 获取考勤统计及明细的筛选条件
 * */

export const getQueryCondition = (id = '') => {
  return fetchRequest({
    url: 'banpai/api/attend/web/queryCondition',
    method: 'get',
    params: { schoolId: id }
  })
}

/*
 * 考勤管理-学生考勤-查询学生考勤明细
 * */

export const queryStudentsDetails = queryInfo => {
  return fetchRequest({
    url: 'banpai/api/attend/web/queryDetails',
    method: 'post',
    data: queryInfo
  })
}

/*
 * 考勤管理-学生考勤-考勤统计
 * */

export const queryStatistic = queryInfo => {
  return fetchRequest({
    url: 'banpai/api/attend/web/queryStatistic',
    method: 'post',
    data: queryInfo
  })
}

/*
 * 保存班级首屏的轮播图分组
 * */

export const shufflingFigure = groupInfo => {
  return fetchRequest({
    url: '/banpai/api/class/saveClassShowGroup',
    method: 'post',
    data: groupInfo
  })
}

/*
 * 获取当前班对应学生
 * */

export const getStudentsLists = paramsInfo => {
  return fetchRequest({
    url: '/banpai/base/getStudents',
    method: 'get',
    params: paramsInfo
  })
}

/*
 * 保存班训
 * */
export const saveClassExtended = classData => {
  return fetchRequest({
    url: '/banpai/api/class/saveClassMotto',
    method: 'post',
    data: classData
  })
}

/*
 * 获取周次
 * */

export const queryTermWeek = queryInfo => {
  return fetchRequest({
    url: '/banpai/api/moral/queryTermWeek',
    method: 'get',
    params: queryInfo
  })
}

/*
 * 班级德育-评分体系-获取体系
 * */
export const queryMoralDefine = queryInfo => {
  return fetchRequest({
    url: 'banpai/api/moral/queryMoralDefine',
    method: 'get',
    params: queryInfo
  })
}

/*
 * 获取德育体系下教师可评分的班级
 * */

export const queryMoralClassByTeacher = queryInfo => {
  return fetchRequest({
    url: '/banpai/api/moral/queryMoralClassByTeacher',
    method: 'post',
    data: queryInfo
  })
}

/*
 * 获取德育体系班级的学生列表
 * */

export const queryClassStudents = queryInfo => {
  return fetchRequest({
    url: '/banpai/api/class/queryClassStudents',
    method: 'get',
    params: queryInfo
  })
}

/*
 * 班级德育-评分体系-获取老师可评分的班级及班级已被评次数
 * */
export const queryTeacherAuthClass = queryInfo => {
  return fetchRequest({
    url: 'banpai/api/moral/queryTeacherAuthClass',
    method: 'post',
    data: queryInfo
  })
}

/*
 * 班级德育-评分明细-获取老师评分权限
 * */
export const queryTeacherAuthority = teacherInfo => {
  return fetchRequest({
    url: 'banpai/api/moral/queryTeacherAuthority',
    method: 'post',
    data: teacherInfo
  })
}

/*
 * 获取学校的评分明细
 * */

export const queryScoreItemList = queryInfo => {
  return fetchRequest({
    url: '/banpai/api/moral/queryScoreItemList',
    method: 'post',
    data: queryInfo
  })
}

/*
 * 查询班级列表
 * */
export const queryClassByEnrolYear = queryInfo => {
  return fetchRequest({
    url: '/banpai/api/moral/queryClassByEnrolYear',
    method: 'post',
    data: queryInfo
  })
}

/*
 * 班级德育-修改指标-获取指标
 * */
export const queryMoralDefineRule = moralCode => {
  return fetchRequest({
    url: 'banpai/api/moral/queryMoralDefineRule',
    method: 'get',
    params: moralCode
  })
}

/*
 * 班级德育-评分明细-登记评分
 * */

export const submitRatingScore = queryInfo => {
  return fetchRequest({
    url: 'banpai/api/moral/submitRatingScore',
    method: 'post',
    data: queryInfo
  })
}

/*
 * 查询年级
 * */
export const getGrade = queryInfo => {
  return fetchRequest({
    url: '/base/grade/getGrade',
    method: 'get',
    params: queryInfo
  })
}

/*
 * 获取班级评分信息
 * */

export const queryClassScoreDetail = queryInfo => {
  return fetchRequest({
    url: '/banpai/api/moral/queryClassScoreDetail',
    method: 'post',
    data: queryInfo
  })
}

/*
 * 获取班级评分信息
 * */

export const queryScoreDetail = queryInfo => {
  return fetchRequest({
    url: '/banpai/api/moral/queryScoreDetail',
    method: 'post',
    data: queryInfo
  })
}

/*
 * 基于手机号换tocken
 * */

export const getAccessToken = queryInfo => {
  return fetchRequest({
    url: '/base/uc/service/getAccessToken',
    method: 'get',
    params: queryInfo
  })
}

/*
 * 扫码登录
 * */

export const getPassphrase = queryInfo => {
  return fetchRequest({
    url: '/banpai/api/device/service/getPassphrase',
    method: 'post',
    data: queryInfo
  })
}

/*
 * wx扫码登录
 * */

export const getwxConfigURL = queryInfo => {
  return fetchRequests({
    url: '/TeacherWechat/api/teacher/wechat/service/getWXConfig',
    method: 'get',
    params: queryInfo
  })
}

/*
 * 获取教室
 * */

export const getTeacherClassRome = queryInfo => {
  return fetchRequests({
    url: '/TeacherWechat/api/teacher/wechat/service/getTeacherClassRome',
    method: 'post',
    data: queryInfo
  })
}

/*
 * 登录班牌
 * */

export const bindSchool = queryInfo => {
  return fetchRequest({
    url: '/banpai/api/device/service/bindSchool',
    method: 'post',
    data: queryInfo
  })
}

/*
 * 上传人脸图片并识别
 * */

export const uploadFaceImg = queryInfo => {
  return fetchRequest({
    url: '/banpai/api/face/file/upload',
    method: 'post',
    data: queryInfo
  })
}

/*
 * 获取信息扫脸
 * */

export const getUserInfo = queryInfo => {
  return fetchRequest({
    url: '/TeacherWechat/auth/getUserInfo',
    method: 'get',
    params: queryInfo
  })
}

/*
 * 获取学生信息
 * */

export const getTokenValueByStu = queryInfo => {
  return fetchRequest({
    url: '/weChat/user/getTokenValue.do',
    method: 'get',
    params: queryInfo
  })
}

/*
 * 获取老师信息数据
 * */

export const getTeacherInfo = queryInfo => {
  return fetchRequest({
    url: '/TeacherWechat/api/teacher/wechat/service/getTeacherInfo',
    method: 'post',
    data: queryInfo
  })
}

// 扫脸-上传扫脸图片
export const submitFace = data => {
  return fetchRequest({
    url: '/banpai/api/face/user/submitFace',
    method: 'post',
    data: data
  })
}

/*
 * 在线课堂-获取文件上传授权
 * */

export const createOSSPolicy = queryInfo => {
  return fetchRequest({
    url: '/banpai/api/repo/createOSSPolicy',
    method: 'get',
    params: queryInfo
  })
}

/*
 * 在线课堂-获取加密后的accessKey
 * */

export const getStamp = queryInfo => {
  return fetchRequest({
    url: '/banpai/api/repo/getStamp',
    method: 'get',
    params: queryInfo
  })
}

/*
 * 在线课堂-保存文件信息
 * */

export const saveFileInfo = queryInfo => {
  return fetchRequest({
    url: '/banpai/api/repo/saveFileInfo',
    method: 'post',
    data: queryInfo
  })
}

/*
 * 风采模式-查询风采列表
 * */

export const getRecordList = queryInfo => {
  return fetchRequest({
    url: '/banpai/show/getRecordList',
    method: 'post',
    data: queryInfo
  })
}

/*
 * 风采模式-删除风采
 * */

export const deleteRecordByCode = queryInfo => {
  return fetchRequest({
    url: '/banpai/show/deleteRecordByCode',
    method: 'get',
    params: queryInfo
  })
}

/*
 * 风采相册-保存风采相册
 * */

export const saveOrUpdateGroup = queryInfo => {
  return fetchRequest({
    url: '/banpai/fc/group/saveOrUpdateGroup',
    method: 'post',
    data: queryInfo
  })
}

/*
 * 获取风采相册
 * */

export const queryGroup = groupDetails => {
  return fetchRequest({
    url: '/banpai/fc/group/queryGroup',
    method: 'post',
    data: groupDetails
  })
}

/*
 * 风采相册-获取风采相册内容
 * */

export const queryContentByGroup = queryInfo => {
  return fetchRequest({
    url: '/banpai/fc/group/queryContentByGroup',
    method: 'get',
    params: queryInfo
  })
}

/*
 * 风采相册-删除风采相册
 * */

export const deleteGroup = queryInfo => {
  return fetchRequest({
    url: '/banpai/fc/group/deleteGroup',
    method: 'get',
    params: queryInfo
  })
}

/*
 * 风采相册-相册置顶
 * */

export const toppingGroup = queryInfo => {
  return fetchRequest({
    url: '/banpai/fc/group/toppingGroup',
    method: 'post',
    data: queryInfo
  })
}

/*
 * 权限-查询教师可见的班级
 * */

export const queryClassByTeacher = queryInfo => {
  return fetchRequest({
    url: '/banpai/base/queryClassByTeacher',
    method: 'post',
    data: queryInfo
  })
}

/*
 * 班级概况-保存班级logo
 * */

export const saveClassLogo = queryInfo => {
  return fetchRequest({
    url: '/banpai/api/class/saveClassLogo',
    method: 'post',
    data: queryInfo
  })
}
/*
 * 通知公告-保存修改
 * */

export const insertOrUpdateNotice = queryInfo => {
  return fetchRequest({
    url: '/banpai/api/notice/insertOrUpdateNotice',
    method: 'post',
    data: queryInfo
  })
}

/*
 * 风采模式-验证是否覆盖或者重叠
 * */

export const checkFc = queryInfo => {
  return fetchRequest({
    url: '/banpai/show/checkFc',
    method: 'post',
    data: queryInfo
  })
}

/*
 * 风采模式-发布风采
 * */

export const release = queryInfo => {
  return fetchRequest({
    url: '/banpai/show/release',
    method: 'post',
    data: queryInfo
  })
}

/*
 * 风采模式-获取单独的详情
 * */

export const getRecordDetail = queryInfo => {
  return fetchRequest({
    url: '/banpai/show/getRecordDetail',
    method: 'get',
    params: queryInfo
  })
}

/**
 * 班级维度数据统计
 */

export const studentCheckRecordStatistics = queryInfo => {
  return fetchRequestss({
    url: `/awardcard/api/student-check-record-statistics`,
    method: 'post',
    data: queryInfo
  })
}
