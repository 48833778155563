import React from 'react'
import PropTypes from 'prop-types'
// @material-ui/core component
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'

const pageLoadingStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '50px 0'
  },
  tip: {
    marginTop: '20px'
  }
})

const PageLoading = ({ ...props }) => {
  const classes = pageLoadingStyles()
  const { tip } = props
  return (
    <div className={classes.root}>
      <CircularProgress />
      {tip && <p className={classes.tip}>{tip}</p>}
    </div>
  )
}

PageLoading.propTypes = {
  tip: PropTypes.string.isRequired
}
PageLoading.defaultProps = {
  tip: '加载中...'
}

export default PageLoading
