import { action, observable } from 'mobx'
class CircularizeStore {
  constructor(rootStore) {
    this.rootStore = rootStore
  }
  @observable notifyRecord = localStorage.getItem('notifyRecord')
    ? JSON.parse(localStorage.getItem('notifyRecord'))
    : {}

  @action.bound
  setCurrentNotifyRecord = currentInfo => {
    this.notifyRecord = currentInfo
    localStorage.setItem('notifyRecord', JSON.stringify(currentInfo))
  }
}

export default CircularizeStore
