import React from 'react'
import ReactDOM from 'react-dom'
import CustomMessage from './Message'
function createNotification() {
  const div = document.createElement('div')
  div.id = 'customMessage'
  document.body.appendChild(div)
  return {
    addNotice(notice) {
      // eslint-disable-next-line react/no-render-return-value
      return ReactDOM.render(<CustomMessage {...notice} />, div)
    },
    destroy() {
      ReactDOM.unmountComponentAtNode(div)
      document.body.removeChild(div)
    }
  }
}

let notification
const message = (variant, content, duration = 3000, onClose, isOpen = true) => {
  // 确保实例的唯一性
  if (notification) notification = null
  notification = createNotification()
  return notification.addNotice({ variant, content, duration, onClose, isOpen })
}

export default {
  info(content, duration, onClose) {
    return message('info', content, duration, onClose)
  },
  success(content, duration, onClose) {
    return message('success', content, duration, onClose)
  },
  error(content, duration, onClose) {
    return message('error', content, duration, onClose)
  },
  loading(content = 'Loading...', duration = 0, onClose) {
    return message('loading', content, duration, onClose)
  }
}
